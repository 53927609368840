import React, { useState } from "react";
import QuestionCard from "./QuestionCard";

const questions = [
    {
        id: 1,
        text: "How adventurous are you feeling today?",
        options: [
            { text: "Keep it sweet and simple 🍫", value: 1 },
            { text: "A little mysterious 🤔", value: 2 },
            { text: "I’m ready for something thrilling! 🎢", value: 3 },
            { text: "Let’s get wild and fiery 🔥", value: 4 },
        ],
    },
    {
        id: 2,
        text: "Are you open to fantasy or role-playing elements?",
        options: [
            { text: "Bring on the creativity! 🎭", value: 1 },
            { text: "I prefer to stay grounded. 🌍", value: 0 },
        ],
    },
    {
        id: 3,
        text: "Which themes spark your interest the most?",
        options: [
            { text: "Romantic 💕", value: 1 },
            { text: "Playful and silly 😄", value: 2 },
            { text: "Adventurous and daring 🧗", value: 4 },
            { text: "Historical or fantasy 🔮", value: 3 },
        ],
    },
    {
        id: 4,
        text: "How intense do you want the experience to feel?",
        options: [
            { text: "Light and breezy 🌬️", value: 1 },
            { text: "A little excitement is fine ⚡", value: 2 },
            { text: "Let’s push the boundaries 🔥", value: 3 },
        ],
    },
    {
        id: 5,
        text: "How often do you want to try new ideas?",
        options: [
            { text: "Occasionally, when the mood strikes 🌟", value: 1 },
            { text: "Once a week sounds good 📅", value: 2 },
            { text: "I’m always up for trying something new! 🎉", value: 3 },
        ],
    },
];

const Interview = ({ onComplete }) => {
    document.title = "Sexual Role Play Ideas | Spice Up Your Nights";
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const handleAnswer = (answer) => {
        setIsTransitioning(true);

        setTimeout(() => {
            setAnswers([...answers, answer]);
            if (currentQuestion + 1 < questions.length) {
                setCurrentQuestion(currentQuestion + 1);
            } else {
                const risqueScore = answers.reduce(
                    (sum, ans) => sum + ans.value,
                    answer.value
                );
                onComplete(risqueScore);
            }
            setIsTransitioning(false);
        }, 500); // Match the transition duration
    };

    return (
        <div className="relative flex flex-col items-center w-full max-w-2xl mx-auto">
            <div
                className={`absolute w-full transition-all duration-500 ${
                    isTransitioning ? "opacity-0 transform translate-y-8" : "opacity-100"
                }`}
            >
                <QuestionCard
                    key={questions[currentQuestion].id}
                    question={questions[currentQuestion]}
                    onAnswer={handleAnswer}
                />
            </div>
        </div>
    );
};

export default Interview;
