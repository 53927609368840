const boss = {
    "title": "The Boss and Employee",
    "route": "boss-and-employee",
    "description": "A workplace scenario where one is the boss and the other is a mischievous employee.",
    "tags": ["workplace", "role-play", "authority", "playful", "dynamic", "fun"],
    "minScore": 4,
    "participants": [
      {
        "title": "Boss",
        "role": "Maintain authority but allow the employee to challenge boundaries slightly.",
        "details": [
          "Start by establishing a professional tone and clear authority.",
          "Respond to playful challenges with firmness, but leave room for humor.",
          "Set small tasks or objectives for the employee to create structure.",
          "Engage in witty back-and-forth dialogue to keep the interaction dynamic.",
          "Balance authority with lightheartedness to encourage playful banter."
        ],
        "products": [
          {
            "id": 16,
            "title": "Executive Desk Set",
            "url": "https://www.amazon.com/Dacasso-Brown-Bonded-Leather-5-Piece/dp/B008VLH39Q?crid=1R48Q34C8F0IL&dib=eyJ2IjoiMSJ9.JCxpdBpnFa4r_O5PyJ5uaAXoQO9FQI_bPfEzcuvZPGlzLc7Ixf_KbGsXBpOml4oDhoiIAj40IemOnO2YHOcVh-zOy1tD6zN4som3APbpifNNf9MUCfjzuEo4IjkbhAjg7-Qm3XIRm88f1WzeR2OMPcHOgNqXJVe_Bsjx-9NeKgjvKVCBabnV90TWTmCu0JmWwHkJgWIrO_RqPXW7yx3W2RNFdiV3QdbmhPR1V-fsGfTlGBhuWU25xcE2r7YEei_rumQb63SZUcP6X4D9BuadDWBdbuAMbyMHD9ORgyzR6q0-hEljgjmzsLHlCY9cbNxW3PGFReAHE6yTW7kxIv5U0vobEh-spJ44AKrUovGhGGYGLSn3HZ5D_ITKKfwvo944eydUgbgNfUZM88A6aP_RXxe7VPpF0k1y26ei1qWDvvMJLsowCNOx-2Ak0eTemorI.6E7UXHaip3tFVEHh-5SOPx8h4aSSPp5009EpTEfWfk0&dib_tag=se&keywords=Executive%2BDesk%2BSet%2Bfor%2Bparty&qid=1735619981&sprefix=executive%2Bdesk%2Bset%2Bfor%2Bparty%2Caps%2C114&sr=8-5&th=1&linkCode=ll1&tag=srpsite-20&linkId=57c138946afd62744b7c61e101a2398e&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/31csUs+KdUL._AC_US40_.jpg",
            "alt-text": "A desk set to create a professional boss-like atmosphere"
          },
          {
            "id": 17,
            "title": "Blazer",
            "url": "https://www.amazon.com/COOFANDY-Stretch-Knitted-Fashion-Wedding/dp/B0CYLLQBSX?crid=LQJ14XFQ3AIB&dib=eyJ2IjoiMSJ9.nRtROQLv41HZeVPTUOU06jcc_zffcFQ8GJpUv7h7cYzWsW8T0n7HqQ-SQbbdbz2ITbrLtSNU0DB8dUm5PX0pkYDwHf3VormYbQVfAPubqqHD2sZw8ZG9N8FybKlBLHKVCA3oFermXPdjI4q4QTOeoQdcHUzmm-bKX515rgnZ8Pk1PD_c84kyNTftYRMtEnUVWLbdHYADx72005QYf9IAVeGb5TzaRPi93NJiUmzKKs2bLjfSyLbu9XGVEtu54jQzP_ohYiYhx6aN9vT7i_3E6zs_F5-q9Jxke1fZxVZQ6eo.4xWqjVcUz7HVkNdGc79M0Y3jTmr_9uc1UdLLb2RV6gg&dib_tag=se&keywords=mens+blazer&qid=1735620040&sprefix=mens+blazer%2Caps%2C142&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=srpsite-20&linkId=123cf26e7cb0a7b9071784828cefad7d&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/41Z+FPn1ItL._AC_SR38,50_.jpg",
            "alt-text": "A sharp blazer to enhance the boss persona"
          }
        ]
      },
      {
        "title": "Employee",
        "role": "Be playful and push boundaries in a lighthearted but professional manner.",
        "details": [
          "Start with small, cheeky actions or comments to test the boss’s reaction.",
          "Maintain professionalism while subtly bending the rules.",
          "Engage in playful banter that challenges authority in a respectful way.",
          "Suggest humorous alternatives to the boss’s requests to keep things fun.",
          "Use exaggerated compliance or over-the-top gestures to add comedic effect."
        ],
        "products": [
          {
            "id": 18,
            "title": "Office Calendar",
            "url": "https://www.amazon.com/Calendar-2025-2025-Months-January-2025-June-Planning/dp/B0DFWZKNDF?th=1&linkCode=ll1&tag=srpsite-20&linkId=9fdd00dd9d611ade61d25feb359d4b60&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/51O3DtNSc5L._AC_US40_.jpg",
            "alt-text": "An Office Calendar"
          },
          {
            "id": 19,
            "title": "Cheeky Mug",
            "url": "https://www.amazon.com/Worlds-Office-Mifflin-Ceramic-Coworkers/dp/B0BBN8KC4S?crid=2VY5LFSMCNWQ6&dib=eyJ2IjoiMSJ9.6lKlxtyxokaYag4ZLsAoWNwpVljYGScgVbO_nY3-4hGLfxLRuHvRv-rAm351_H0OVt3YeN1DByop9kCY3IimRoc0HoalRSrjhHoUBDL_9Tr5fWc1zVCFNR3Q4zGICakhN4GoBEa5DS45ZX6MKZ5s7khWWQS9QCAqOYcX1k4KZ9JLMyXob5soxKK-3h8-X9qoP96lsvCGmHHzMbC0pTYgkPIkHW9-po0nXFochq-iHoKcXiO57R8IIslNo_v8grtaM81WdPOgyqg1eB44zlGImhxUXRAioE3kf9qJAzLO9lQIHPho6kASG2fgeN06kJnofujuynMyvvWZ4daHXDB39JwEK1tea2MfBBdgHqRLV7F23W2Mm22-OeNbWroGKnUPob-tT8FG7BmZoc0IIdNOUPTwoWxaH1GBSJQuxmReaRCKn5IFXgcndCkzb14J5jty.H7-lcG3aBhGCI-D2MIoJqJ7LYRBTkAY43TpWTBLBhm8&dib_tag=se&keywords=cheeky%2Bboss%2Bmug&qid=1735619923&sprefix=cheeky%2Bboss%2Bmug%2Caps%2C234&sr=8-5&th=1&linkCode=ll1&tag=srpsite-20&linkId=65f86b517a9201bc95e5875c2aa830c6&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/31XdFs45ufL._AC_US100_.jpg",
            "alt-text": "A playful mug to showcase the mischievous employee role"
          }
        ]
      }
    ],
    "setting": {
      "ambiance": "Set up a workplace environment with desks, office supplies, and appropriate props to create a realistic atmosphere.",
      "props": [
        "Office desk",
        "Computer or laptop",
        "Office supplies",
        "Professional-looking chair"
      ],
      "dialogue": [
        "Begin with a formal conversation about work or a specific task.",
        "Incorporate playful banter or subtle challenges from the employee to keep the dynamic interesting."
      ]
    },
    "variations": [
      {
        "title": "Performance Review",
        "description": "The scenario involves a formal review, where the employee pushes boundaries while the boss maintains control."
      },
      {
        "title": "New Hire",
        "description": "The employee is new and mischievously tests the boss's patience during onboarding."
      },
      {
        "title": "Role Reversal",
        "description": "The employee temporarily takes on the boss's role in a humorous twist."
      },
      {
        "title": "Office Party",
        "description": "The interaction takes place during an office party, adding a more relaxed and playful vibe."
      }
    ],
    "communicationTips": [
      "Set clear boundaries beforehand to ensure mutual comfort and enjoyment.",
      "Discuss the level of professionalism and playfulness to include in the scenario.",
      "Stay in character to maintain immersion, but allow for pauses or adjustments as needed."
    ]
  }
  
export default boss;