import { default as boss } from './boss.js';
import { default as doctor } from './doctor.js';
import { default as fantasy } from './fantasy.js';
import { default as fitness } from './fitness.js';
import { default as stranger } from './stranger.js';

const ideas = [
  boss,
  doctor,
  fantasy,
  fitness,
  stranger,
];

export default ideas;
