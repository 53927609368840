const doctor = {
    "title": "Doctor and Patient",
    "route": "doctor-and-patient",
    "description": "Explore a playful doctor-patient dynamic during a routine checkup.",
    "tags": ["medical", "intimate", "trust", "role-play", "dynamic", "fun"],
    "minScore": 4,
    "participants": [
      {
        "title": "Doctor",
        "role": "Conduct a thorough examination while maintaining a calm and professional demeanor.",
        "details": [
          "Greet the patient warmly and professionally.",
          "Ask routine questions to establish the scenario.",
          "Perform a physical examination using props like a stethoscope and gloves.",
          "Create playful diagnoses and interact with curiosity or intrigue.",
          "Introduce variations like being a specialist or dealing with an emergency."
        ],
        "products": [
          {
            "id": 8,
            "title": "Stethoscope",
            "url": "https://www.amazon.com/Ever-Ready-First-Aid-Stethoscope/dp/B00HW8EEOG?crid=2EYRS6M9JLGI1&dib=eyJ2IjoiMSJ9.E3HNkzH47sYgivS7BMcEdPjW6i0ytzT-M5_jwtaIswMK1ou_4-VR9slx-pFvn6Z773XMVb42Yq9ynzOA8TzYjzwM2BEdFTIczSqp0ldCl_HxNQTC5Myo6ygvgtu4o831hB21H9GY1XX479gWLw5kn6olHuOzQlK6ZQkQZQ6D4ORxM27q9zxv6XB5dNu2SlBgYaukxcR2pcmPMN6nVh2PdNxxto0yx_-5qLoNiOApYX4.cfZ0_mS-fldzpLhOoz9ut3czzsVze8wkOf7I7UEkx44&dib_tag=se&keywords=stethoscope%2Bcheap&qid=1735619476&sprefix=stethoscope%2Bcheap%2Caps%2C125&sr=8-3&th=1&linkCode=ll1&tag=srpsite-20&linkId=aa2bf457122d8176242576109c0f563b&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/415jBUF7oKL._AC_US40_.jpg",
            "alt-text": "A realistic stethoscope for doctor role-play"
          },
          {
            "id": 10,
            "title": "White Coat",
            "url": "https://www.amazon.com/VOGRYE-Professional-Sleeve-Unisex-White2/dp/B07D8G82G8?crid=1YLWPQZVNM2KW&dib=eyJ2IjoiMSJ9.5P4kNx7AqPKsZhJO5HL_dstW9n7tyKV2m8cW9PaQggP9Lirql7Mc2hiRyUsiU3ZMWfiXpwsLfgKyW_ugcg3f-W6YySVU9hb7el5ckA0cSU5kbIlZR7cuXZhDh9Dvy6wfrv5XN2Dg7ZdfrWPRvvX-s1eRxTiWmF-9DRc8lYiBu9vFMgkdBAupbvrQ1d9Y4PYpBxUvavlISHO0K2thRxB_odKebKbxtgoT6fY8I672YTVLSqhwxaLmuD-epiIex-tKnNH_LcpcEe7ZPOABbwxudcmL8hZivn7TysCLEHR8eik.Pkurk673rNI4-J7LSkMAZxqPQJTJEa7GrCLorzdK4jQ&dib_tag=se&keywords=white+coat+doctor&qid=1735619403&sprefix=white+coat+doctor%2Caps%2C173&sr=8-3-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&smid=A137J5FE375LHY&linkCode=ll1&tag=srpsite-20&linkId=90d93f523e9e6c3ea5e66a4484fafd74&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/31Zp9TjbC6L._AC_SR38,50_.jpg",
            "alt-text": "A classic white coat for authentic doctor role-play"
          }
        ]
      },
      {
        "title": "Patient",
        "role": "Be cooperative but also curious about the process to spark engaging interaction.",
        "details": [
          "Answer the doctor's questions thoughtfully and engage with curiosity.",
          "Introduce playful challenges or exaggerated symptoms.",
          "Add humor or drama by pretending to be nervous or skeptical.",
          "Play into variations such as suggesting alternative diagnoses or treatments.",
          "Adjust the tone by exploring scenarios like routine checkups or urgent visits."
        ],
        "products": [
          {
            "id": 9,
            "title": "Medical Gown",
            "url": "https://www.amazon.com/Linteum-Textile-1-Piece-Snowflakes-HOSPITAL/dp/B01CK2Q734?crid=2GC5X2TQWPWHY&dib=eyJ2IjoiMSJ9.9wliPmCtorzrvT3AagcxVwEkJahBX5lyevT1StRkwyqYYBppgvRN7b-NxtHVsQGKI5aqOoGggEJNORWyKpC0DnCz8KUUM61kSJG7LFmL4ruVKn0KMAvrGQKL42nFkpjjPhEIahxkd11dkpPNq4pc2P5M6-AZ-e-AfczEDN1AorHx53YdEvmd1rzIakgJvEnK3rt8WC69oE-OPMc5to7ImmKyKbDZ-Bc_DJFvd5FhWP8.cZpG-gJgu5z_khckN7x_GjBMxgRVugXq2fRpP15IooE&dib_tag=se&keywords=medical%2Bgown&qid=1735619541&sprefix=medical%2Bgown%2Caps%2C124&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=srpsite-20&linkId=15080d4cfc1cd072d373ce3f8d51bea3&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/61cL-Phow5L._SX38_SY50_CR,0,0,38,50_.jpg",
            "alt-text": "A comfortable medical gown for patients"
          },
          {
            "id": 11,
            "title": "Hospital Bed Sheet",
            "url": "https://www.amazon.com/Hospital-Knitted-T-Shirt-Quality-Pillowcase/dp/B089Q4B7C8?crid=3V46S6BJJ84H6&dib=eyJ2IjoiMSJ9.mXOH1dHb8LvqNyFqDW5AtdkDV0nAvBbaJfDS1etR1oO76-y02NmsNYEvx04xBIp4UacOKKeogSSJJWN8w_AwLfVMOdWw6p3Xcwknfog3QtchhqIaCnMSFJrpUjLgDfAVa_XnVmG8ivT00G23DX8i0GGvAGsewthUxAa1QjbIpv7wLpiAdpEbbjks4E07iUOagNe6wyFqVRWllYPNaKHxRQCwjrIjIlgHDeZIj8QtQVYMDiIHaFMXywYVz8pn7YpF2x8gJqx3vU2kQpu9Q9qXNFwTgQ7Wp0KRv-TN7vQE-17Ffi4VuI4qbVymU_IF84weOe-blGIOFcA2m2vXmV7y-YR3foOAwtHW4_lnMVVmIYWmY7NrH9f9_gT7d7uBHOf18bLiLkmNx9SXaq6qPpV33Ar0AlObGBLTg5yVaB4NkvDov3ZcxArx4nw5RXNDaNOi.eT5mJI_Ic4NXrbe9YSjp1lXbbpdIzhZjMglqn6ReiuE&dib_tag=se&keywords=hospital%2Bbed%2Bsheets&qid=1735619593&sprefix=hospitabl%2Bbed%2Caps%2C124&sr=8-7&th=1&linkCode=ll1&tag=srpsite-20&linkId=5441bb21581dfb573d6bd17e9a81e4cc&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/41DNuT8OaaL._AC_US100_.jpg",
            "alt-text": "A bed sheet to set the stage for doctor-patient role-play"
          }
        ]
      }
    ],
    "setting": {
      "ambiance": "Set up the environment to resemble a clinic or examination room using props and lighting.",
      "props": [
        "Stethoscope",
        "Notepad",
        "Gloves",
        "Small table for instruments"
      ],
      "dialogue": [
        "Establish the scenario with engaging conversations like discussing symptoms or conducting checkups.",
        "Maintain a balance of professionalism and playful intrigue."
      ]
    },
    "variations": [
      {
        "title": "Specialist Appointment",
        "description": "The doctor specializes in a specific area like dermatology or cardiology, adding depth to the scenario."
      },
      {
        "title": "Role Reversal",
        "description": "The patient challenges the doctor’s authority by suggesting alternative treatments or diagnoses."
      },
      {
        "title": "Emergency Visit",
        "description": "Heighten the drama with an urgent care scenario requiring quick action."
      },
      {
        "title": "Unusual Diagnoses",
        "description": "Introduce humor by diagnosing creative or exaggerated conditions."
      }
    ],
    "communicationTips": [
      "Establish boundaries and comfort levels before starting.",
      "Use safe words or signals to ensure a positive experience.",
      "Stay in character while remaining open to pauses or adjustments."
    ]
  };  

  export default doctor;