import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ideas from "../../data/ideas";

const IdeaDetails = () => {
    const { ideaId } = useParams(); // Get the route parameter
    const navigate = useNavigate(); // For navigation actions
    const idea = ideas.find((idea) => idea.route === ideaId); // Match based on the route

    useEffect(() => {
        if (idea) {
            document.title = `Sexual Role Play Ideas | ${idea.title}`;
        } else {
            document.title = `Sexual Role Play Ideas | Idea Not Found`;
        }
    }, [idea]);

    if (!idea) {
        return (
            <div className="max-w-3xl mx-auto p-6 text-center">
                <h2 className="text-3xl font-primary mb-4 text-accent">
                    Idea Not Found
                </h2>
                <button
                    onClick={() => navigate("/ideas")}
                    className="bg-accent text-white px-4 py-2 rounded-md hover:bg-glow transition"
                >
                    Explore Other Ideas
                </button>
            </div>
        );
    }

    return (
        <div className="max-w-5xl mx-auto p-6 space-y-8">

            {/* Title and Description */}
            <div>
                <h2 className="text-3xl font-primary text-highlight mb-4">
                    {idea.title}
                </h2>
                <p className="text-muted text-lg mb-6">{idea.description}</p>
            </div>

            {/* Setting */}
            <div className="bg-card p-4 rounded-lg shadow-md space-y-4">
                <h3 className="text-xl font-semibold text-highlight">Setting</h3>
                <p className="text-muted">{idea.setting.ambiance}</p>
                <ul className="list-disc pl-6 text-muted">
                    {idea.setting.props.map((prop, index) => (
                        <li key={index}>{prop}</li>
                    ))}
                </ul>
                <ul className="list-disc pl-6 text-muted">
                    {idea.setting.dialogue.map((dialogue, index) => (
                        <li key={index}>{dialogue}</li>
                    ))}
                </ul>
            </div>

            {/* Tags */}
            <div className="flex flex-wrap gap-2">
                {idea.tags.map((tag, index) => (
                    <span
                        key={index}
                        className="bg-muted text-white px-2 py-1 rounded-md text-sm"
                    >
                        #{tag}
                    </span>
                ))}
            </div>

            {/* Participants */}
            <div className="space-y-6">
                {idea.participants.map((participant, index) => (
                    <div
                        key={index}
                        className="bg-card p-4 rounded-lg shadow-md space-y-4"
                    >
                        <h3 className="text-xl font-semibold text-highlight">
                            {participant.title}
                        </h3>
                        <p className="text-muted">{participant.role}</p>
                        <ul className="list-disc pl-6 text-muted">
                            {participant.details.map((detail, idx) => (
                                <li key={idx}>{detail}</li>
                            ))}
                        </ul>
                        <div className="flex flex-wrap gap-4">
                            {participant.products
                                .filter(
                                    (product) =>
                                        !product.url.includes(
                                            "example-affiliate-link.com"
                                        )
                                )
                                .map((product) => (
                                    <a
                                        key={product.id}
                                        href={product.url}
                                        target="_blank"
                                        className="flex items-center gap-2 bg-muted/10 p-2 rounded-md shadow-sm hover:shadow-md transition"
                                        onClick={() => {
                                            // Log the event to GA4
                                            window.gtag('event', 'click', {
                                                event_category: 'affiliate',
                                                event_label: product.title,
                                                //value: product.id, // Optional: Add a numeric value (e.g., product ID or price)
                                                destination_url: product.url,
                                            });
                                        }}
                                    >
                                        <img
                                            src={product.thumbnail}
                                            alt={product["alt-text"]}
                                            className="w-16 h-16 object-cover rounded-md"
                                        />
                                        <span className="text-sm text-highlight">
                                            {product.title}
                                        </span>
                                    </a>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
            {/* Amazon Affiliate Disclosure */}
            <p className="text-sm text-center text-muted bg-card p-2 rounded-md shadow-sm">
                As an Amazon Associate, I earn from qualifying purchases.
            </p>                 

            {/* Variations */}
            <div className="space-y-4">
                <h3 className="text-xl font-semibold text-highlight">
                    Variations
                </h3>
                {idea.variations.map((variation, index) => (
                    <div
                        key={index}
                        className="bg-muted/10 p-4 rounded-md shadow-sm"
                    >
                        <h4 className="text-lg font-medium text-accent">
                            {variation.title}
                        </h4>
                        <p className="text-sm text-muted">
                            {variation.description}
                        </p>
                    </div>
                ))}
            </div>

            {/* Communication Tips */}
            <div className="bg-card p-4 rounded-lg shadow-md space-y-4">
                <h3 className="text-xl font-semibold text-highlight">
                    Communication Tips
                </h3>
                <ul className="list-disc pl-6 text-muted">
                    {idea.communicationTips.map((tip, index) => (
                        <li key={index}>{tip}</li>
                    ))}
                </ul>
            </div>
      

            {/* Navigation Button */}
            <div className="flex justify-center mt-8">
                <button
                    onClick={() => navigate("/ideas")}
                    className="bg-accent text-white px-6 py-3 rounded-md hover:bg-glow transition"
                >
                    Explore Other Ideas
                </button>
            </div>
        </div>
    );
};

export default IdeaDetails;
