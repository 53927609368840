const stranger = {
    "title": "The Seductive Stranger",
    "route": "seductive-stranger",
    "description": "One of you plays a mysterious stranger in a bar who the other must seduce.",
    "tags": ["mystery", "seduction", "intimacy", "role-play", "fun", "bar-scene"],
    "minScore": 4,
    "participants": [
      {
        "title": "Stranger",
        "role": "Be mysterious and alluring. Respond only when approached and keep details about yourself vague.",
        "details": [
          "Position yourself in a corner or at the bar to appear aloof yet approachable.",
          "Respond minimally and with cryptic answers to spark curiosity.",
          "Maintain an air of mystery by deflecting personal questions.",
          "Use subtle body language and gestures to convey intrigue.",
          "Keep interactions playful yet elusive, leaving the Approacher wanting to know more."
        ],
        "products": [
          {
            "id": 12,
            "title": "Cocktail Set",
            "url": "https://www.amazon.com/bartender-kit-cocktail-shaker-set-cocktail-set-cocktail-kit/dp/B07PGVTYS8?dib=eyJ2IjoiMSJ9.nNvuuTUyDNO9mYBS-KSfMahGaHjSg_1SiE6u4MD7yS90eoUHurD9aUlGZ4ulpF87rPqY7__YrWPUZg42Wv2fx4AKaSLhITmT0p01efl9fot3c6_D_9KpwLGKWt-ImwXqasmhj_K3y7Tdc9jO7pk4uaYVZsW7O0ex89_3ozFiUD2Z_be35JsQ53W_OOw2yKuxjUFmsDXe5y9_ywTY6xy5qLvoUsuXdYdKfkeyx-2Q5pWBnzGVGRUsd0T96_tdyUb_14fHfvIthgLuuAEe-uK3jkbzidj9o_IH95ChLvqeaUM.YjZBim4BiucTkQ2nNPTbFwcPMkoCEprx5Hck1kO0TFI&dib_tag=se&keywords=cocktail%2Bset&qid=1735620271&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=srpsite-20&linkId=58413842f698647459e774e656493e2a&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/51HnFUC-fiL._AC_US100_.jpg",
            "alt-text": "A stylish cocktail set to complete the bar atmosphere"
          },
          {
            "id": 13,
            "title": "Mystery Attire",
            "url": "https://www.amazon.com/Ekouaer-Bridesmaid-Bathrobe-Lightweight-Loungewear/dp/B0CHFG2H64?crid=589WKD2AOGIL&dib=eyJ2IjoiMSJ9.j-vXJNIwzdbXv9501mY4EQHq-dp2UNd6L0GMrB3jkz--IJr-BgR7vjfh3onjPQKe1WOht-ADW4UDHaqpxkkKE-p2pa1hpcjI7_P02YpQh7mWyH5uTI8zoLvlUl5X-X-Wdol0NdkXh9Q7AH8-wZ9c7MCuuTjBkkB2o_cYuzZKWjbStoqzG_EE-GF-hQMKNJqpv2wV7PdMCiy2EKNgI4K2mgNGbq2DfAKZd8RLilGZOerY2q9NWozlpBgNh-Q6OLOpLMMyBQrNERpCedkpES6p3Fv86jpo_02fuR39bV_1yCo.rHmf0vnK9AMrsY0cu2gy9tkeAkF_J9XhYGxoHfRsOLQ&dib_tag=se&keywords=mysterious+outfit&qid=1735620329&sprefix=mysterious+outfit%2Caps%2C110&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=srpsite-20&linkId=55f3c89f3d59ec3535fc4f09ed5410ae&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/31Ey9xQH5-L._AC_SR38,50_.jpg",
            "alt-text": "An elegant outfit to enhance the allure of the stranger role"
          }
        ]
      },
      {
        "title": "Approacher",
        "role": "Confidently engage the stranger, trying to uncover details while maintaining intrigue.",
        "details": [
          "Approach with confidence, using a mix of charm and curiosity.",
          "Ask open-ended questions to encourage the stranger to reveal just enough to keep you intrigued.",
          "Maintain an air of sophistication and avoid coming on too strong.",
          "Play into the stranger’s mysterious persona by responding with subtle intrigue of your own.",
          "Experiment with different conversational tactics to keep the dynamic engaging and playful."
        ],
        "products": [
          {
            "id": 14,
            "title": "Signature Drink",
            "url": "https://www.amazon.com/cocktail-mixing-glass-set-bartender-kit/dp/B08CXYC91Q?crid=32S339NDSVI6K&dib=eyJ2IjoiMSJ9.fL7YLFMDcjOVlyOtDUKu8qU9iq-dOyQgqyIr84d8t5-lf3L_4SgLPw6qE5CKQM5VauJ8uwKGasubiMONAVPVf4R4gFJ0E_qr9_TN9jxfz_65Ubo44Hh2ESDJV49zB89RN212vk13v9sxE1kiY6xIxf0BMnlXXhONRcX09irX6Oxv6GUOfriMxDMhYbX2zr21_IbTgqFBN2oKAsvIUYXRLqc3tBMJPfVobmAl93h7Hpy-AV5UTzWt-tTVMITQbZqdumK5V--tc4b2k-LNrxuayl01aEDubTYT6yQuVW-vCS8.NtKBzpDlv-CoJSacie0sBe9-kk-QxYRMU5q1z5_aM2Y&dib_tag=se&keywords=old+fashioned+kit&qid=1735620398&sprefix=old+fas%2Caps%2C153&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=srpsite-20&linkId=2e5bb7975be3a18158870ee3d41a44ad&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/51P+badoy5L._AC_US100_.jpg",
            "alt-text": "A curated drink recipe to complement the bar setting"
          },
          {
            "id": 15,
            "title": "Sleek Outfit",
            "url": "https://www.amazon.com/MAGE-MALE-Notched-Button-Wedding/dp/B0B2JTMSC4?crid=3CQDGUHBTQWT&dib=eyJ2IjoiMSJ9.h6nGP8WA8u3aEQXqsIv1S4Tui8myNOLKHRqTJg-jC0SwlI1UUJdteIvTV3x3zQVn5_6QMrqJA6fIOY6Mk_nQ4wiVBJ7KgajZXM72CGI9yM5NQlrm5s7LGFp9I_CBU0MxChA5ne3-4V1MkZvEhWalpJL-f09gdXAmzx-iEnRn6CXSmlENuPbrjxnXAa_AuqpBYyPkBsoZ3rVJxrnw43he8DNfJvC-rrUolQXAKB01hjQpAxG0Y6fmshisET4-LZp-Nzp-C981yIdIu760hXwDZ3_pfSBa9nSzKynG4v6dlvI.PrmF2Al46QJuiycc9w6cVYpoB2nsgNj9_AQ3Of5UctA&dib_tag=se&keywords=james+bond+costume+for+men&qid=1735620446&sprefix=james+bond+costumer+for+men%2Caps%2C122&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=srpsite-20&linkId=9aed33457b075cf80f24da003d6d5071&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/311yVyTUJUL._AC_SR38,50_.jpg",
            "alt-text": "A stylish outfit for making a confident impression"
          }
        ]
      }
    ],
    "setting": {
      "ambiance": "Create a bar-like atmosphere with dim lighting, background music, and props like drink glasses or a bar counter.",
      "props": [
        "Cocktail glasses",
        "Bar stools",
        "Dim lighting",
        "Background music"
      ],
      "dialogue": [
        "Start with subtle eye contact or a brief glance before engaging.",
        "Use smooth and engaging dialogue to set the tone and maintain the intrigue."
      ]
    },
    "variations": [
      {
        "title": "Out-of-Town Visitor",
        "description": "The stranger is visiting from out of town and carries a hidden secret, adding depth to the interaction."
      },
      {
        "title": "Role Reversal",
        "description": "The Approacher takes on the mysterious role while the Stranger becomes the one intrigued and curious."
      },
      {
        "title": "Secret Agenda",
        "description": "The Stranger has a hidden motive for being at the bar, creating an opportunity for deeper storytelling."
      },
      {
        "title": "Accidental Meeting",
        "description": "The Approacher and Stranger have a surprising connection, discovered through their interaction."
      }
    ],
    "communicationTips": [
      "Set boundaries beforehand to ensure comfort and enjoyment.",
      "Discuss the level of mystery and detail you’d like to include.",
      "Stay in character to maintain immersion, but be flexible with pauses or adjustments as needed."
    ]
  }

  export default stranger;