import React from "react";
import { Link } from "react-router-dom";
import ideas from "../../data/ideas"

const Ideas = () => {
    document.title = "Sexual Role Play Ideas | Ideas";
    return (
        <div className="max-w-5xl mx-auto p-6">
            <h2 className="text-3xl font-primary mb-6 text-highlight text-center">
                Role-Playing Ideas
            </h2>
            <div className="flex flex-wrap justify-center gap-6">
                {ideas.map((idea, index) => (
                    <Link
                    key={index}
                    to={idea.route}
                    className="bg-card w-full sm:w-[300px] rounded-lg shadow-card p-6 flex flex-col justify-between transition transform hover:scale-105 hover:shadow-lg"
                    >
                        <h3 className="text-xl font-semibold text-highlight mb-2">
                            {idea.title}
                        </h3>
                        <p className="text-muted text-sm">
                            {idea.description}
                        </p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Ideas;

