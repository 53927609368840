import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
    return (
<nav className="bg-card p-4 shadow-md">
    <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-primary text-highlight hover:no-underline focus:no-underline active:no-underline">
            Sexual Role Play
        </Link>
        <ul className="flex space-x-4">
            <li>
                <Link to="/ideas" className="hover:text-muted">
                    Ideas
                </Link>
            </li>            
            <li>
                <Link to="/about" className="hover:text-muted">
                    About
                </Link>
            </li>
            {/* <li>
                <Link to="/contact" className="hover:text-muted">
                    Contact
                </Link>
            </li> */}
        </ul>
    </div>
</nav>

    );
};

export default Navigation;
