import React, { useState } from "react";
import { Route, Routes} from "react-router-dom";
import { Navigation, Spinner, About, Ideas, IdeaDetails } from "./components";
import Interview from "./components/interview/Interview";

function App() {
    //const location = useLocation();

    const [risqueScore, setRisqueScore] = useState(null);

    return (
            <div className="bg-dark-gradient min-h-screen">
                <Navigation />
                <main className="flex flex-col items-center justify-center py-20">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                risqueScore === null ? (
                                    <Interview onComplete={setRisqueScore} />
                                ) : (
                                    <Spinner risqueScore={risqueScore} />
                                )
                            }
                        />
                        <Route path="/about" element={<About />} />
                        <Route path="/ideas" element={<Ideas />} />
                        <Route path="/ideas/:ideaId" element={<IdeaDetails />} />
                        {/* <Route path="/contact" element={<Contact />} /> */}
                    </Routes>
                </main>
            </div>
    );
}

export default App;
