import React from "react";

const Contact = () => {
    return (
        <div className="max-w-3xl mx-auto bg-card p-6 rounded-lg shadow-card text-highlight">
            <h2 className="text-3xl font-primary mb-4">Contact Us</h2>
            <p className="text-muted">
                We'd love to hear from you! Reach out with questions, feedback, or suggestions.
            </p>
            <form className="mt-6">
                <label className="block text-highlight mb-2" htmlFor="name">
                    Name
                </label>
                <input
                    className="w-full p-2 mb-4 border rounded-lg bg-dark-gradient text-highlight"
                    type="text"
                    id="name"
                    placeholder="Your name"
                />
                <label className="block text-highlight mb-2" htmlFor="email">
                    Email
                </label>
                <input
                    className="w-full p-2 mb-4 border rounded-lg bg-dark-gradient text-highlight"
                    type="email"
                    id="email"
                    placeholder="Your email"
                />
                <label className="block text-highlight mb-2" htmlFor="message">
                    Message
                </label>
                <textarea
                    className="w-full p-2 mb-4 border rounded-lg bg-dark-gradient text-highlight"
                    id="message"
                    rows="5"
                    placeholder="Your message"
                />
                <button
                    type="submit"
                    className="px-6 py-2 bg-accent text-highlight rounded-lg shadow-button hover:opacity-90 transition ease-smooth"
                >
                    Send
                </button>
            </form>
        </div>
    );
};

export default Contact;
