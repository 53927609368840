import React from "react";

const About = () => {
    document.title = "Sexual Role Play Ideas | About Us";
    return (
        <div className="max-w-3xl mx-auto bg-card p-6 rounded-lg shadow-card text-highlight">
            <h2 className="text-3xl font-primary mb-4">About Us</h2>
            <p className="text-muted">
                Welcome to our platform! We're here to help couples explore their creativity and deepen their connection through fun and engaging ideas.
            </p>
            <p className="mt-4 text-muted">
                Our goal is to make role-playing easy and accessible for everyone, providing inspiration and tools to spark your imagination.
            </p>
        </div>
    );
};

export default About;
