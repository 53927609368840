const fantasy = {
    "title": "Fantasy Adventure",
    "route": "fantasy-adventure",
    "description": "Take on roles of a knight and a captured royalty who must escape together.",
    "tags": ["fantasy", "adventure", "role-play", "escape", "teamwork", "imagination"],
    "minScore": 4,
    "participants": [
      {
        "title": "Knight",
        "role": "Be brave and decisive, focusing on rescuing and guiding the royalty to safety.",
        "details": [
          "Start by creating a plan to free the royalty and navigate through obstacles.",
          "Show courage by confronting dangers and making decisive choices.",
          "Use heroic and motivational dialogue to reassure the royalty.",
          "Improvise solutions to challenges encountered during the escape.",
          "Maintain a protective demeanor while respecting the royalty’s input."
        ],
        "products": [
          {
            "id": 20,
            "title": "Knight’s Sword",
            "url": "https://www.amazon.com/GIFTEXPRESS-Plastic-Pretend-Costume-Accessory/dp/B08CG2DWLZ?crid=19JUR1AN93240&dib=eyJ2IjoiMSJ9.4j1nEf4ajCxlG0kkhGzfdwr5u95x8ZGLU_K6XZLzTXSWmcnCxUhA6zMcBi28rqUkVeQLr2r5n7lG_yd1YJ_zYajUGmmIW5i457kdWVEHjhGc5NyJe58KMrVDswmg9QYHTB23P1xiztKf0l8C7KI2ZrD4En02JPKabG5nfepzMuZKXhTCoZCJRc6COhrG6Jf-RjkoLU68RRZqsiZi7-OWX2ei9aMnsWX4Po0YtO6O1zWQkcKIrWUTip3bwjGgbbgAzk2WT4qDbZbpj8LNmb3mKzf6CQSlJpe5psW-rqnp7LoJxg2dLTcOTPlsW0YdO6C-c1cSOPxdHaQDAEqCknD_uuYLaOQVpfhQfMu0Ka6yXOI2g-V7weGrfG-tIQUwBervdyVvUOcARJUUykQZEk7x-xE32TjpwQUcrFJR2RmvP4iqrmz-1KOscBrnuR32t5lo.frFvoQEewjKyhMGB3_F4-48nRddpdxVnHxZuoMLoL0I&dib_tag=se&keywords=Knight%E2%80%99s+Sword&qid=1735619798&sprefix=knight+s+sword%2Caps%2C169&sr=8-8&linkCode=ll1&tag=srpsite-20&linkId=3d284a2ab50f2e1d9f4e12a8a16b6ea0&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/41EEc9SaPOL._AC_SR38,50_.jpg",
            "alt-text": "A realistic knight’s sword prop for role-play adventures"
          },
          {
            "id": 21,
            "title": "Knight’s Armor",
            "url": "https://www.amazon.com/ReneeCho-Gladiator-Costume-Warrior-Medium/dp/B07WGMCCNH?crid=2LXWKLR8OPCX1&dib=eyJ2IjoiMSJ9.RSP5GwqmDyk3EqL1-NncmdxaametPcXONpux7zeBOegGmp5MkBkT0cER8pxHTOs3FvhCzD004rOJ_KxwHGuDDkob0tGa4yLboGrJk7lo6gcriYzfuR2Ylyg1VWF7SEtksHrvoaAgWA2cONB5rNlb1XqXOc0nF9-GvZQyTheCG1uhuE5TZvrHnVsaFpGTONqg9ztrvW8je2JfXgBzk14F1yyO6MsJhmMzWvy9SgF4AZ8CVnufsdXdxYAcgqNKSLZvUputxhv_Bey0BOXH2x77mp685nMw10W8Vc7jZpS4GCI.k5BM_16vNNvIL7PDPmX3hdMNYthTPCavEKKPcATAxfg&dib_tag=se&keywords=adult+Knight%E2%80%99s+armor&qid=1735619873&sprefix=adult+knight+s+armor%2Caps%2C97&sr=8-3-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&linkCode=ll1&tag=srpsite-20&linkId=7f6f7d6c6dc747bdd058880456b8f321&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/410AYRYTgjL._AC_SR38,50_.jpg",
            "alt-text": "A detailed knight’s armor set for immersive role-play"
          }
        ]
      },
      {
        "title": "Royalty",
        "role": "Act grateful but also provide clever solutions to aid in the escape.",
        "details": [
          "Express gratitude and trust in the knight’s abilities.",
          "Offer clever suggestions or insights to help overcome challenges.",
          "Balance vulnerability with moments of strength and determination.",
          "Engage in collaborative dialogue to develop a sense of teamwork.",
          "Add a touch of elegance and poise to your actions and words."
        ],
        "products": [
          {
            "id": 22,
            "title": "Royal Gown",
            "url": "https://www.amazon.com/Shoulder-Elegant-Evening-Mermaid-Cocktail/dp/B0B5GSMSZP?crid=2LXGY1XZ1XO8N&dib=eyJ2IjoiMSJ9.Y1MTButMLlhCvv4Zjv54735VpKnfKCXhCx4HsWe2GyawAfzbMj-odKTjFwhY6c8ACcplBVVmxLKvD9zHZbLIPB3b5B7QMbFPacAKnVfPh6x4ob7DJxot6mvxahgtYc7Nt_C3p97MC2urwvFQGu6rPQYTlyKPAcvaQlYKZfjOTo6J4nJKa0qerLEJkOS3fSK-RbSouQUX4W4selMYJ6LfvCO02WCINRDYL2NG_cLktEApyYfyMjKPCekOBde4hdkN0vBz2mBqTtbaAXyh_17uJR9wJsrD1JV8a6YlV0ozwKpE93OSzG5alHUDWCpJCadaKJM6nmhBpNaNi8P9SjuHyaYa3HmC5IESZOlUwpih-3HBb6pqteEwZsJnEX0uA-L2U353OFr42cvSmxk9hHMk-BdYSLk3pL1HszFt8FvEpUGj3oaVVooQrWfaVLl4G9s9.xK7wU3WRBqzVkFQYHwuO_IY2boOjm-hUbzKE6dpUUdc&dib_tag=se&keywords=Royal+Gown&qid=1735619684&sprefix=royal+gown%2Caps%2C141&sr=8-18&linkCode=ll1&tag=srpsite-20&linkId=c896ddd7fcda2323d5bcf39f208b5afd&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/31hxpyEg78L._AC_SR38,50_.jpg",
            "alt-text": "An elegant royal gown for a regal role-play experience"
          },
          {
            "id": 23,
            "title": "Crown",
            "url": "https://www.amazon.com/Princess-Rhinestone-Accessories-Birthday-Ceremony/dp/B0CN96VZXC?crid=18DVZ5PVK0WOM&dib=eyJ2IjoiMSJ9.QKcO__poQmVS5s7KmbwuO8Uq-tirgztTMtjMeydPSuve_ssg8xMwYhxkalh_zBDechj62kg2MVCTp4yrMXCoWhYZ7i7u5zMLIqa2Sr_Rv-gRHH-eBvn-VC5I3Bi_cQoXYoQv7bZGjLc_82Rs-KneB50gwXAsuZVCNGiXKleperTBYpruo8xqqhz1pvq5dyp8H1aNCuZA_BntnFVqh6gj_v-58vM0nXZ92s3DydKnFHM7770ri_dxNqhA6ZCErAL7sYDFPaD4yM_LOtwCjL0fC1j37Mk6AsgQIFMYoLXvEUc.WhkrgAANSt9JcIbtm9weIorz85JYmwuEITDlh9waCLY&dib_tag=se&keywords=Royal%2BCrown&qid=1735619748&sprefix=royal%2Bcrown%2Caps%2C131&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=srpsite-20&linkId=9ed5d715bf078f1721691f4d88023617&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/41B2-3cGsHL._SS40_.jpg",
            "alt-text": "A regal crown to enhance the royalty role"
          }
        ]
      }
    ],
    "setting": {
      "ambiance": "Create a medieval setting with elements like stone walls, torches, and mystical props.",
      "props": [
        "Toy weapons (swords, shields)",
        "Faux dungeon or castle backdrop",
        "Candles or lanterns for lighting",
        "Treasure chests or maps"
      ],
      "dialogue": [
        "Begin with the knight discovering the royalty in captivity.",
        "Use dialogue to build a sense of urgency and teamwork as the escape progresses."
      ]
    },
    "variations": [
      {
        "title": "Forbidden Forest",
        "description": "The escape leads through an enchanted forest filled with magical creatures and hidden dangers."
      },
      {
        "title": "Undercover Escape",
        "description": "The royalty disguises themselves, and the knight must protect their secret while escaping."
      },
      {
        "title": "Treacherous Alliance",
        "description": "The knight and royalty must temporarily ally with a former enemy to secure freedom."
      },
      {
        "title": "Hero’s Betrayal",
        "description": "A twist where the knight faces moral dilemmas, adding tension to the dynamic."
      }
    ],
    "communicationTips": [
      "Set clear boundaries beforehand to ensure mutual comfort and enjoyment.",
      "Discuss the level of fantasy and realism you’d like to include in the scenario.",
      "Stay in character to maintain immersion, but be flexible for pauses or adjustments as needed."
    ]
  }
  
export default fantasy;