const fitness = {
    "title": "Fitness Trainer and Client",
    "route": "fitness-trainer-and-client",
    "description": "A gym scenario where one is a trainer helping the other with intense workouts.",
    "tags": ["fitness", "gym", "role-play", "motivation", "teamwork", "effort"],
    "minScore": 4,
    "participants": [
      {
        "title": "Trainer",
        "role": "Provide intense workout routines and encourage the client to push their limits.",
        "details": [
          "Start by assessing the client’s fitness level and setting achievable yet challenging goals.",
          "Demonstrate exercises with proper form and provide clear instructions.",
          "Motivate the client with encouraging words and a confident demeanor.",
          "Push the client to exceed their perceived limits in a safe and supportive way.",
          "Celebrate small victories and provide feedback to build the client’s confidence."
        ],
        "products": [
          {
            "id": 24,
            "title": "Workout Timer",
            "url": "https://www.amazon.com/gp/aw/d/B0B8NP1Z19?_encoding=UTF8&pd_rd_plhdr=t&aaxitk=085d78ec9505dff155c4c62e6b128f21&hsa_cr_id=0&qid=1735620081&sr=1-2-9e67e56a-6f64-441f-a281-df67fc737124&pd_rd_w=IyE21&content-id=amzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942%3Aamzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_p=8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_r=H51YMJVAJAW4BWYKFJY3&pd_rd_wg=llB9C&pd_rd_r=bf1f1778-83ff-4623-afd1-8ddd5653686e&th=1&linkCode=ll1&tag=srpsite-20&linkId=530dec419ad0a42a2880b51dbe7175fb&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/31bWbxa12hL._AC_US40_.jpg",
            "alt-text": "A compact timer to track workout intervals"
          },
          {
            "id": 25,
            "title": "Resistance Bands",
            "url": "https://www.amazon.com/Resistance-Exercise-Training-Physical-Workouts/dp/B0BY4DFMQ7?crid=AL95OUB03425&dib=eyJ2IjoiMSJ9.fWsfSf4YxFuv3iAJm00IYA0aS1lBULPkaKus9C9CGuNy7RiJn4NMu7rBUuQNliT3GwmVSn4m4KwbFPFBUtCwBe5XPiEgFMuI3epBl4RXmCL1kEDRzqiyCd_H9RGpUBU5jb4bOq4mUxZJQyui8ic7Bw06W1Raq4bkCM78aQBXdwBJ5NISmHyEPRDntbHAeQBCSvu9VFA8E4OpKs72CwpStwwKaO2NSGL7v2lO6Is818eLb2CVmj61avaSTosGlMALxef-75AkWTWE_LGBE7WjBP9LoZTFk9Tk84KWs0QW2a0.sfBdHJK285zcbDufzBBAYKSPZbW-Qk5vjuAhn1wYWV0&dib_tag=se&keywords=resistance%2Bbands%2Bwith%2Bhandles&qid=1735620141&s=sporting-goods&sprefix=resistenc%2Csporting%2C125&sr=1-3-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&linkCode=ll1&tag=srpsite-20&linkId=1b1fee71c7e98fa6f79aa766f239b449&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/41-rFNBeH5L._AC_US40_.jpg",
            "alt-text": "A set of resistance bands for versatile workout routines"
          }
        ]
      },
      {
        "title": "Client",
        "role": "Follow the trainer's instructions while demonstrating effort and dedication.",
        "details": [
          "Listen carefully to the trainer’s guidance and follow instructions with determination.",
          "Demonstrate visible effort during exercises to show commitment.",
          "Ask questions or seek clarifications to show engagement and interest.",
          "Express gratitude and enthusiasm for the trainer’s support.",
          "React positively to feedback and strive to improve performance."
        ],
        "products": [
          {
            "id": 26,
            "title": "Fitness Mat",
            "url": "https://www.amazon.com/dp/B08HSJKJ3H?_encoding=UTF8&pd_rd_i=B08HSJKJ3H&qid=1735620184&pd_rd_w=1vQyK&content-id=amzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942%3Aamzn1.sym.8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_p=8591358d-1345-4efd-9d50-5bd4e69cd942&pf_rd_r=08STM79G9WPRYPKK6GWZ&pd_rd_wg=LPlIo&pd_rd_r=0a4f74ff-0fa0-479f-bfbd-89c735e9911d&pd_rd_plhdr=t&th=1&linkCode=ll1&tag=srpsite-20&linkId=8ef088ac33ecd4ec444b46b2aad8fd97&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/41pAE7Vc71L._AC_US40_.jpg",
            "alt-text": "A durable fitness mat for comfort during workouts"
          },
          {
            "id": 27,
            "title": "Gym Towel",
            "url": "https://www.amazon.com/Luxury-Gym-Towels-Sweat-Absorbent/dp/B08WPZJ3RN?crid=1OXS5CWC4PC2H&dib=eyJ2IjoiMSJ9._9aPLr5oziGS_ceE9CbtpMtCwpWuzom0-mRE1Nv_M_rTFGCMLZSCrAg5z5Q8RbeOjLy3OpP08hSTC0fo4Zaln1zq3jarqUlzTGrnCz6AY83fSQZTTHZroMT0_E1-MHuS4vup4CsIlGvn-KXqebDouoiQEPgGWWHTTRASsaSgs-Z6wueZxm-uOl516zJPjDt_kQBGxVRkeRcdcMZWfkJMLXgL-oWYV0La_SNWnH-ORspxlPKgSjbEN2xUKuYuPswXJMrhCNyc1fb6xyUw31IO7CdTBijTVD0706G_O-edc3idkO5jTMV5Hdz2e5p1mXiRArTNwP8r9uvvXW5WQhynqe4W1o0MI8Bs7ocTjlY_AXQ.Ou_aPbFBoJsSSduwObimga6RbiMCzaJcJclUK3d7lKo&dib_tag=se&keywords=gym%2Btowel&qid=1735620229&s=sporting-goods&sprefix=gym%2Btowel%2Csporting%2C105&sr=1-8&th=1&linkCode=ll1&tag=srpsite-20&linkId=db96b75caf4d0bb9df3c1c5efb1ebea3&language=en_US&ref_=as_li_ss_tl",
            "thumbnail": "https://m.media-amazon.com/images/I/51chdGnKceL._AC_US100_.jpg",
            "alt-text": "A high-absorbency towel for gym use"
          }
        ]
      }
    ],
    "setting": {
      "ambiance": "Create a gym-like environment with workout equipment, motivational music, and a focus on fitness.",
      "props": [
        "Dumbbells or kettlebells",
        "Resistance bands",
        "Workout timer",
        "Yoga or fitness mat"
      ],
      "dialogue": [
        "Start with the trainer explaining the workout plan and demonstrating exercises.",
        "Use motivational phrases to encourage the client and maintain an upbeat tone."
      ]
    },
    "variations": [
      {
        "title": "Personal Best Challenge",
        "description": "The trainer helps the client achieve a new personal best in a specific exercise or fitness goal."
      },
      {
        "title": "Beginner's Session",
        "description": "The client is new to fitness, and the trainer focuses on teaching the basics with a supportive approach."
      },
      {
        "title": "Advanced Techniques",
        "description": "The trainer introduces advanced techniques or high-intensity interval training to challenge the client."
      },
      {
        "title": "Outdoor Training",
        "description": "The workout session takes place outdoors, incorporating running, bodyweight exercises, and nature-inspired challenges."
      }
    ],
    "communicationTips": [
      "Set boundaries beforehand to ensure mutual comfort and enjoyment.",
      "Discuss fitness levels and expectations to create a realistic and engaging scenario.",
      "Stay in character to maintain immersion, but allow for pauses or adjustments as needed."
    ]
  }
  


export default fitness;