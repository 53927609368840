import React from "react";
import { Link } from "react-router-dom"; // Import Link for React Router navigation

const Card = ({ title, children, to }) => {
    const Wrapper = to ? Link : "div";

    return (
        <Wrapper
            to={to}
            className="bg-card text-highlight p-6 rounded-lg shadow-card space-y-4 transition transform hover:scale-105 hover:shadow-lg"
        >
            {title && <h2 className="text-xl font-primary text-accent">{title}</h2>}
            <div className="text-muted">{children}</div>
        </Wrapper>
    );
};

export default Card;
